import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "@/components/ui/dialog"

export function Modal({ children, description, triggerButton, setOpen, isOpen, heading }: {
    children: React.ReactNode,
    description: string,
    triggerButton: React.ReactNode,
    setOpen: (open: boolean) => void,
    isOpen: boolean,
    heading: string
}) {
    return (
        <Dialog onOpenChange={setOpen} open={isOpen}>
            <DialogTrigger asChild>
                {triggerButton}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{heading}</DialogTitle>
                    <DialogDescription>
                        {description}
                    </DialogDescription>
                </DialogHeader>
                {children}
            </DialogContent>
        </Dialog>
    )
}
