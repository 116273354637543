"use client"

import { ABI } from '@/app/tournament-abi';
import useClerkSWR from '@/hooks/useClerkHook';
import { AllowListWallet, Tournament } from '@/lib/schema';
import { formatNumber, groupTournamentsByGameId } from '@/lib/utils';
import { useUser } from "@clerk/nextjs";
import { sendGAEvent } from '@next/third-parties/google';
import { DialogClose } from '@radix-ui/react-dialog';
import { ToastAction } from '@radix-ui/react-toast';
import { Loader2 } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { erc20Abi, isAddress, zeroAddress } from 'viem';
import { useAccount, useBalance, useChainId, useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { useCallsStatus, useCapabilities, useWriteContracts } from "wagmi/experimental";
import { ALLOWLIST_KEY } from '../admin/CreateTournament';
import { CREDITS_KEY } from '../CreditsBox';
import { Dropdown } from '../Dropdown';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Select, SelectGroup, SelectItem, SelectLabel } from '../ui/select';
import { toast } from '../ui/use-toast';

const getRequiredCredits = (selectedTournament: Tournament, creditTier: number) => {
    if (creditTier === null || creditTier === 0) return BigInt(0)
    return BigInt(selectedTournament[`tier${creditTier}CreditCost` as 'tier1CreditCost' | 'tier2CreditCost' | 'tier3CreditCost'])
}

function formatCost(cost: bigint | undefined, decimals: number | undefined, symbol: string | undefined): string {
    if (!cost || !decimals || !symbol) return '0';

    const value = Number(cost) / 10 ** decimals;
    if (symbol.toUpperCase() === 'USDC') {
        return `${value.toFixed(1)} USDC`;
    }

    if (decimals === 18) {
        return value < 0.01 ? `${value.toFixed(4)} ${symbol}` : `${value.toFixed(0)} ${symbol}`;
    }

    return `${value.toFixed(decimals)} ${symbol}`;
}

function BuyCredits({ activeTournaments, selectedTournament, beneficary, setOpen, shouldRedirectToGameOnSuccess, directBuy }: { activeTournaments: Tournament[], selectedTournament: Tournament, beneficary: boolean, setOpen: (value: boolean) => void, shouldRedirectToGameOnSuccess: boolean, directBuy: boolean }) {
    const { address, connector } = useAccount()
    const { user } = useUser()
    const chainId = useChainId()
    const router = useRouter()

    let groupedTournaments = groupTournamentsByGameId(activeTournaments)
    const [isEndingSoon, setIsEndingSoon] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [selectedTournamentFromDropdown, setSelectedTournamentFromDropdown] = useState<Tournament | null>(directBuy ? selectedTournament : null)

    const allowlistKey = ALLOWLIST_KEY(selectedTournamentFromDropdown?.tournamentId || selectedTournament.tournamentId)
    console.log(allowlistKey)
    const { data: userAllowlist }: { data: { data: AllowListWallet[] } } = useClerkSWR(allowlistKey)
    const { data: balance, refetch: refetchBalance } = useBalance({
        address,
        query: {
            staleTime: 30000,
            enabled: Boolean(address && selectedTournamentFromDropdown?.paymentToken)
        },
        token: selectedTournamentFromDropdown?.paymentToken === zeroAddress ? undefined : selectedTournamentFromDropdown?.paymentToken as `0x${string}`,
    })
    const [creditTier, setCreditTier] = useState<number>(2)
    const [requiredTokensForCredits, setRequiredTokensForCredits] = useState<bigint | null>(null)
    const [conversionRate, setConversionRate] = useState<number>(0)
    const [beneficaryAddress, setBeneficaryAddress] = useState<string | undefined>(!beneficary ? address : '0x')

    const { data: creditData, isLoading: creditDataLoading, mutate: refetchCredits } = useClerkSWR(
        CREDITS_KEY(selectedTournamentFromDropdown?.tournamentId)
    )

    // Determine if there's an active tournament with a defined address.
    const hasActiveTournamentWithAddress = Boolean(activeTournaments.find(t => t)?.address);

    // Simplify the enabled logic: only enable if the paymentToken is not the zero address, and both user and tournament addresses are defined.
    const shouldFetchAllowance = selectedTournamentFromDropdown?.paymentToken !== zeroAddress &&
        Boolean(address) &&
        Boolean(selectedTournamentFromDropdown?.address) &&
        hasActiveTournamentWithAddress;

    const { data: allowance, isSuccess: fetchAllowingSuccess, refetch: refetchAllowance } = useReadContract({
        address: selectedTournamentFromDropdown?.paymentToken as `0x${string}`,
        abi: erc20Abi,
        functionName: "allowance",
        query: {
            enabled: shouldFetchAllowance,
        },
        args: [address as `0x${string}`, selectedTournamentFromDropdown?.address as `0x${string}`],
    });

    useEffect(() => {
        if (!selectedTournamentFromDropdown || !creditTier) return
        const creditsRequired = getRequiredCredits(selectedTournamentFromDropdown, creditTier)
        setRequiredTokensForCredits(creditsRequired)
    }, [creditTier, selectedTournamentFromDropdown])

    useEffect(() => {
        // Check if the tournament is ending within the next hour, if so, set the isEndingSoon state to true
        if (selectedTournamentFromDropdown) {
            const tournamentEndTime = new Date(selectedTournamentFromDropdown.endTime).getTime()
            const currentTime = new Date().getTime()
            if (tournamentEndTime - currentTime < 3600000) {
                setIsEndingSoon(true)
            } else {
                setIsEndingSoon(false)
            }
        }
    }, [selectedTournamentFromDropdown])

    const { writeContracts: writeSwapTokensContracts, data: writeSwapTokensContractsData } = useWriteContracts({
        mutation: {
            onError() {
                setButtonLoading(false)
            },
            onMutate() {
                setButtonLoading(true)
            }
        }
    });

    const { data: callsStatus } = useCallsStatus({
        id: writeSwapTokensContractsData as string,
        query: {
            enabled: !!writeSwapTokensContractsData,
            refetchInterval: (data: any) =>
                data.state.data?.status === "CONFIRMED" ? false : 1000,
        },
    });

    useEffect(() => {
        if (callsStatus?.status === "CONFIRMED") {
            setTimeout(async () => {
                if (!selectedTournamentFromDropdown) {
                    console.log('No selected tournament from dropdown')
                    return
                }
                console.log('Refetching credits')
                await Promise.all([
                    refetchAllowance(),
                    refetchBalance(),
                    refetchCredits(),
                ])
                setOpen(false)
                toast({
                    title: "Tokens Swapped for Credits",
                    description: "GLHF",
                    action: <ToastAction onClick={() => {
                        window.open(`${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${selectedTournamentFromDropdown?.gameId}?tid=${selectedTournamentFromDropdown?.tournamentId}`, '_blank', 'noreferrer')
                    }} altText="Play"><Button variant={'secondary'}>Play</Button></ToastAction>
                })
                console.log("[DEBUG] shouldRedirectToGameOnSuccess", shouldRedirectToGameOnSuccess)
                if (shouldRedirectToGameOnSuccess) {
                    console.log("[DEBUG] Redirecting to game")
                    router.replace(`${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${selectedTournamentFromDropdown?.gameId}?tid=${selectedTournamentFromDropdown?.tournamentId}`)
                }
                setButtonLoading(false)
            }, 4000)

            sendGAEvent('event', 'swap_credits', { email: user?.primaryEmailAddress?.emailAddress || "", amount: creditTier, token: selectedTournamentFromDropdown?.paymentTokenSymbol, wallet: user?.unsafeMetadata.wallet || "" })
        }
    }, [callsStatus])


    const { data: availableCapabilities } = useCapabilities({
        account: address,
        query: {
            enabled: Boolean(connector?.name === 'Coinbase Wallet')
        }
    });

    const capabilities = useMemo(() => {
        if (!availableCapabilities || !chainId) return {};
        const capabilitiesForChain = availableCapabilities[chainId];

        if (
            capabilitiesForChain["paymasterService"] &&
            capabilitiesForChain["paymasterService"].supported
        ) {
            return {
                paymasterService: {
                    url: `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/api/paymaster`,
                },
            };
        }
        return {};
    }, [availableCapabilities, chainId]);

    const needsApproval = Boolean(chainId === selectedTournamentFromDropdown?.chainId
        && requiredTokensForCredits
        && selectedTournamentFromDropdown?.paymentToken !== zeroAddress
        && address)

    const insufficientAllowance = requiredTokensForCredits && fetchAllowingSuccess && (allowance || 0) < requiredTokensForCredits;

    const { data: approvalConfig } = useSimulateContract({
        address: selectedTournamentFromDropdown?.paymentToken as `0x${string}` || activeTournaments.find(t => t)?.paymentToken as `0x${string}`,
        abi: erc20Abi,
        functionName: "approve",
        query: {
            enabled: Boolean(needsApproval && insufficientAllowance && selectedTournamentFromDropdown),
        },
        args: [
            selectedTournamentFromDropdown?.address as `0x${string}` || activeTournaments.find(t => t)?.address as `0x${string}`,
            requiredTokensForCredits || BigInt(0)
        ],
    });

    const { data: legacyApproveData, writeContract: approveWrite } = useWriteContract({
        mutation: {
            onMutate() {
                setButtonLoading(true);
            },
            onError() {
                setButtonLoading(false);
            },
        }
    })

    const { isSuccess: approvalSuccess, data: waitForApproveTransactionHash } = useWaitForTransactionReceipt({
        hash: legacyApproveData
    });

    const handleTierChange = async (tier: 1 | 2 | 3) => {
        setCreditTier(tier)
        const conversionRate = tier === 1 ? 1 : tier === 2 ? 10 : 50
        setConversionRate(conversionRate)
    }

    const handleTournamentChange = async (tournamentId: string) => {
        const newSelectedTournament = activeTournaments.find(t => t.tournamentId == parseInt(tournamentId))
        if (newSelectedTournament) {
            const conversionRate = creditTier === 1 ? 1 : creditTier === 2 ? 10 : 50
            setConversionRate(conversionRate)
            setSelectedTournamentFromDropdown(newSelectedTournament);
        }
    };

    useEffect(() => {
        if (directBuy) {
            handleTournamentChange(selectedTournament.tournamentId.toString())
        }
    }, [directBuy, selectedTournament.tournamentId]);

    // Define whether the paymentToken is the zero address
    const isZeroAddress = Boolean(selectedTournamentFromDropdown && selectedTournamentFromDropdown.paymentToken === zeroAddress)

    // Check the base conditions that are always required
    const baseConditionsMet = Boolean(address && selectedTournamentFromDropdown && creditTier && isAddress(beneficaryAddress as string) && chainId === selectedTournamentFromDropdown?.chainId);

    const nonZeroAddressConditions = {
        hasBalance: Boolean(balance),
        positiveBalance: Boolean(balance && balance?.value > 0),
        hasRequiredTokens: Boolean(requiredTokensForCredits !== undefined),
        ...(connector?.name !== 'Coinbase Wallet' && {
            hasAllowance: Boolean(allowance !== undefined),
            sufficientAllowance: Boolean(allowance && requiredTokensForCredits && allowance >= requiredTokensForCredits)
        })
    };

    const nonZeroAddressConditionsMet = Object.values(nonZeroAddressConditions).every(Boolean);

    const shouldApprove = useMemo(() => {
        if (connector?.name === 'Coinbase Wallet') return false
        if (selectedTournamentFromDropdown?.paymentToken === zeroAddress) return false
        if (!requiredTokensForCredits) return false
        return (allowance || 0) < requiredTokensForCredits;
    }, [allowance, requiredTokensForCredits, selectedTournamentFromDropdown?.paymentToken, connector?.name])

    const isSwapDisabled = shouldApprove ? false : !isZeroAddress ? !baseConditionsMet || !nonZeroAddressConditionsMet : !baseConditionsMet;

    const { data: simulateSwapForCredit, refetch: refetchSimulateSwap } = useSimulateContract({
        address: selectedTournamentFromDropdown?.address as `0x${string}`,
        abi: ABI,
        functionName: 'swapForCredits',
        args: [selectedTournamentFromDropdown?.tournamentId as unknown as bigint, selectedTournamentFromDropdown?.gameId as unknown as bigint, creditTier as unknown as bigint, beneficaryAddress as `0x${string}` || undefined],
        query: {
            enabled: Boolean(!isSwapDisabled && requiredTokensForCredits && selectedTournamentFromDropdown && selectedTournamentFromDropdown?.tournamentId),
        },
    })

    const { writeContract: swapTokensForCredits, data: swapTokensForCreditsData } = useWriteContract({
        mutation: {
            onMutate() {
                setButtonLoading(true);
            },
            onError() {
                setButtonLoading(false);
            },
        }
    })

    const { data: waitForCreditSwapTransactionHash } = useWaitForTransactionReceipt({
        hash: swapTokensForCreditsData
    })

    useEffect(() => {
        if (!waitForCreditSwapTransactionHash) return
        setTimeout(async () => {
            if (!selectedTournamentFromDropdown) return
            await Promise.all([
                refetchAllowance(),
                refetchBalance(),
                refetchCredits(),
            ])
            if (shouldRedirectToGameOnSuccess) {
                router.replace(`${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${selectedTournamentFromDropdown?.gameId}?tid=${selectedTournamentFromDropdown?.tournamentId}`)
            }
            setButtonLoading(false)
        }, 5000)
        sendGAEvent('event', 'swap_credits', { email: user?.primaryEmailAddress?.emailAddress || "", amount: creditTier, token: selectedTournamentFromDropdown?.paymentTokenSymbol, wallet: user?.unsafeMetadata.wallet || "", tournamentId: selectedTournamentFromDropdown?.tournamentId })
        toast({
            title: "Tokens Swapped for Credits",
            description: "GLHF",
            action: <ToastAction onClick={() => {
                window.open(`${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${selectedTournamentFromDropdown?.gameId}?tid=${selectedTournamentFromDropdown?.tournamentId}`, '_blank', 'noreferrer')
            }} altText="Play">Play</ToastAction>
        })

        setOpen(false)
    }, [waitForCreditSwapTransactionHash, writeSwapTokensContractsData])

    useEffect(() => {
        if (!waitForApproveTransactionHash || !approvalSuccess) return
        setButtonLoading(true)
        const refetch = async () => {
            await refetchAllowance();
            await refetchBalance();
        }
        refetch()
        toast({
            title: "Tokens Approved",
            description: "You can now swap your tokens for credits",
        })
        setTimeout(() => {
            setButtonLoading(false)
            refetchSimulateSwap()
        }, 3000)
    }, [waitForApproveTransactionHash, approvalSuccess])

    const buttonLabel = useMemo(() => {
        if (connector?.name === 'Coinbase Wallet') {
            return 'Swap'
        } else if (shouldApprove) {
            return 'Approve'
        } else {
            return 'Swap'
        }
    }, [connector?.name, shouldApprove]);

    const handleClick = async (e: { preventDefault: () => void }) => {
        try {
            e.preventDefault();
            setButtonLoading(true);

            const requiredAmount = getRequiredCredits(selectedTournamentFromDropdown!, creditTier || 0);
            if (connector?.name === 'Coinbase Wallet') {
                handleSwap();
            } else {
                if (shouldApprove && approvalConfig && connector?.name !== 'Coinbase Wallet') {
                    handleApproval();
                } else if (balance && balance.value < requiredAmount) {
                    notifyInsufficientBalance();
                } else {
                    handleSwap();
                }
            }
        } catch (error) {
            console.log(error);
            setButtonLoading(false)
            showErrorToast();
        }
    };

    const handleApproval = () => {
        if (!approvalConfig) return;
        approveWrite(approvalConfig.request);
    };

    const handleSwap = () => {
        if (connector?.name !== 'Coinbase Wallet') {
            console.log('Falling back to legacy wallet swap', simulateSwapForCredit);
            if (!simulateSwapForCredit) return;
            swapTokensForCredits(simulateSwapForCredit.request);
        } else {
            const writes = []
            if (needsApproval) {
                writes.push({
                    address: selectedTournamentFromDropdown?.paymentToken as `0x${string}`,
                    abi: erc20Abi,
                    functionName: "approve",
                    args: [selectedTournamentFromDropdown?.address || activeTournaments.find(t => t)?.address as `0x${string}`, requiredTokensForCredits || BigInt(0)],
                })
            }
            writes.push({
                address: selectedTournamentFromDropdown?.address as `0x${string}`,
                abi: ABI,
                functionName: "swapForCredits",
                args: [
                    BigInt(selectedTournamentFromDropdown?.tournamentId as number),
                    BigInt(selectedTournamentFromDropdown?.gameId as number),
                    BigInt(creditTier as number),
                    beneficaryAddress as `0x${string}`
                ],
            })

            writeSwapTokensContracts({
                contracts: writes,
                capabilities,
            });
        }
    };

    const notifyInsufficientBalance = () => {
        toast({
            title: "Insufficient Balance",
            description: "Top up your balance",
        });
        setButtonLoading(false)
    };

    const showErrorToast = () => {
        toast({
            title: "Something went wrong",
            description: "Please try again",
        });
    };

    // Allowlist check
    groupedTournaments = Object.fromEntries(
        Object.entries(groupedTournaments).map(([category, tournaments]) => [
            category,
            Object.fromEntries(
                Object.entries(tournaments).map(([gameId, tournamentList]) => [
                    gameId,
                    tournamentList.filter(tournament => {
                        if (tournament.gatedByWalletAllowList) {
                            const isUserOnAllowlist = userAllowlist?.data?.find((wallet: AllowListWallet) => wallet.wallet === address);
                            return isUserOnAllowlist;
                        }
                        return true;
                    })
                ]).filter(([_, tournamentList]) => tournamentList.length > 0)
            )
        ]).filter(([_, tournaments]) => Object.keys(tournaments).length > 0)
    );

    // We also need to check if selectedTournament is gatedByAllowlist and if the user is on the allowlist
    const isSelectedTournamentGatedByAllowlist = selectedTournamentFromDropdown?.gatedByWalletAllowList
    const isUserOnAllowlist = userAllowlist?.data?.find((wallet: AllowListWallet) => wallet.wallet === address)
    if (isSelectedTournamentGatedByAllowlist && !isUserOnAllowlist) {
        return (
            <div className="text-white text-center p-4 rounded-lg shadow-md">
                <p className="mb-2">Your connected wallet is not on the allowlist for this tournament.</p>
                <p className="">
                    Contact <span className="text-purple-400">{selectedTournamentFromDropdown?.createdByUsername}</span> to get on the allowlist.
                </p>
            </div>
        )
    }

    return (
        <div className="grid gap-4">
            <div className={`grid grid-cols-4 items-center gap-4 ${selectedTournamentFromDropdown === null ? 'animate-pulse' : ''}`}>
                <Dropdown name={selectedTournamentFromDropdown ? `${selectedTournamentFromDropdown.gameName} - Tournament ${selectedTournamentFromDropdown?.tournamentId}` : 'Select Tournament'}>
                    <Select onValueChange={handleTournamentChange}>
                        {Object.entries(groupedTournaments).map(([category, tournaments]) => (
                            <SelectGroup key={category}>
                                <SelectLabel>
                                    <p className='text-purple-800 text-xl'>{category}</p>
                                </SelectLabel>
                                {Object.entries(tournaments).map(([gameId, tournaments]) => (
                                    <SelectItem className='cursor-pointer' key={gameId} value={tournaments[0].tournamentId.toString()}>
                                        {tournaments[0].communityName ?
                                            `${tournaments[0].communityName}: Tournament ${tournaments[0].tournamentId}` :
                                            `Tournament: ${tournaments[0].tournamentId} (${tournaments[0].gameName})`
                                        }
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        ))}
                    </Select>
                </Dropdown>
            </div>
            {selectedTournamentFromDropdown && <div className='flex space-x-2 my-2'>
                <Button variant={'secondary'} onClick={async (e) => {
                    await handleTierChange(1)
                }} className={` text-white font-bold py-2 px-4 w-1/3 hover:bg-purple-800 ${creditTier === null ? 'animate-pulse' : ''} ${creditTier === 1 ? 'bg-purple-800' : null}`}>
                    {formatCost(BigInt(selectedTournamentFromDropdown?.tier1CreditCost || 0), selectedTournamentFromDropdown?.paymentTokenDecimals, selectedTournamentFromDropdown?.paymentTokenSymbol)}
                </Button>
                <Button variant={'secondary'} onClick={async (e) => {
                    await handleTierChange(2)
                }} className={` text-white font-bold py-2 px-4  w-1/3 hover:bg-purple-800 ${creditTier === null ? 'animate-pulse' : ''} ${creditTier === 2 ? 'bg-purple-800' : null}`}>
                    {formatCost(BigInt(selectedTournamentFromDropdown?.tier2CreditCost || 0), selectedTournamentFromDropdown?.paymentTokenDecimals, selectedTournamentFromDropdown?.paymentTokenSymbol)}
                </Button>
                <Button variant={'secondary'} onClick={async (e) => {
                    await handleTierChange(3)
                }} className={` text-white font-bold py-2 px-4  w-1/3 hover:bg-purple-800 ${creditTier === null ? 'animate-pulse' : ''} ${creditTier === 3 ? 'bg-purple-800' : null}`}>
                    {formatCost(BigInt(selectedTournamentFromDropdown?.tier3CreditCost || 0), selectedTournamentFromDropdown?.paymentTokenDecimals, selectedTournamentFromDropdown?.paymentTokenSymbol)}
                </Button>
            </div>}
            {beneficary ? <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label className='mt-1' htmlFor="beneficiary">Beneficiary</Label>
                <Input onChange={(e) => {
                    if (isAddress(e.target.value)) {
                        setBeneficaryAddress(e.target.value)
                    } else {
                        setBeneficaryAddress(undefined)
                    }
                }} className=" mb-1" type="beneficiary" id="beneficiary" placeholder={"0x123"} />
            </div> : null}
            {selectedTournamentFromDropdown && <div className='flex-col'>
                <span className='text-sm'>Your Tournament {selectedTournamentFromDropdown.tournamentId || selectedTournament.tournamentId} Credits: <p className='text-xl font-bold'>{creditDataLoading ? <Loader2 className='animate-spin h-6 w-6' /> : creditData?.data?.balance || 0}</p></span>
                {isEndingSoon && <Badge className='mt-2 text-white animate-pulse'>Tournament Ending Soon</Badge>}
            </div>}
            {selectedTournamentFromDropdown && <div className='flex text-sm justify-between border-t '>
                <p className="py-4">
                    {balance?.value ? selectedTournamentFromDropdown.paymentToken === zeroAddress ?
                        parseFloat(balance?.value.toString()).toFixed(4) :
                        formatNumber(balance?.value.toString() || BigInt(0).toString()) : 0} {selectedTournamentFromDropdown?.paymentTokenSymbol}
                </p>
                {creditTier !== null && conversionRate && <p className="py-4">
                    Swap {formatNumber(getRequiredCredits(selectedTournamentFromDropdown, creditTier || 0).toString(), selectedTournamentFromDropdown.paymentTokenDecimals)} {selectedTournamentFromDropdown.paymentTokenSymbol} for {conversionRate} Credits
                </p>}
            </div>}
            <DialogFooter>
                <DialogClose asChild>
                    <div className="flex space-x-2 ">
                        {selectedTournamentFromDropdown && <Button className='text-white py-2 px-4 w-full' onClick={() => {
                            window.open(`https://app.uniswap.org/swap?chain=base&outputCurrency=${selectedTournamentFromDropdown?.paymentToken}&inputCurrency=ETH`, '_blank', 'noreferrer')
                        }}>
                            Get {selectedTournamentFromDropdown?.paymentTokenSymbol || selectedTournament.paymentTokenSymbol}
                        </Button>}
                        <Button
                            disabled={isSwapDisabled || buttonLoading}
                            onClick={handleClick}
                            className="text-white font-bold py-2 px-4 w-full min-w-[80px]"
                        >
                            {buttonLoading ? <Loader2 className='animate-spin' /> : buttonLabel}
                        </Button>
                    </div>
                </DialogClose>
            </DialogFooter>
        </div>
    )
}

export default BuyCredits